/* Welcome to Compass. Use this file to define print styles.
 * Import this file using the following HTML or equivalent:
 * <link href="/stylesheets/print.css" media="print" rel="stylesheet" type="text/css" /> */
@media print {
  a[href]:after {
    content: none !important; } }

@page {
  margin-bottom: 15mm;
  overflow: visible; }

html {
  margin-bottom: 40mm; }

header,
footer,
#navbar-mobile,
#bloc-rechercher,
.linked_event,
.hp_newsletter,
.btn-group,
.wrap_retour,
.consult_actus,
.navigate,
.wrap-bloc-twitter,
.btn-consult {
  display: none; }

.figure {
  border: none !important; }

.col-md-push-8,
.col-md-pull-4 {
  left: 0 !important;
  right: 0 !important; }

/* .col-md-4 {
	float: right;
	width: 270px;
}
.col-md-8 {
	float: left;
	width: 740px;
} */
.container > .row > .col-md-8 {
  width: 100% !important; }

.node-type-evenement .figure {
  min-height: 0 !important; }

.node-type-evenement .top-content {
  padding-top: 0; }

.wrap-titre h1 {
  width: 100%;
  font-family: 'Libre Franklin';
  color: #474748 !important;
  font-size: 35px;
  font-weight: 600 !important;
  line-height: 40px; }

.wrap-titre .content_emetteur {
  color: #9cc400 !important; }

.visu_event {
  float: left;
  margin-right: 40px;
  width: 211px; }

.wrap-info-pratique {
  margin-top: -194px; }

.sidebar-actus, .sidebar-fiche {
  display: none !important; }

/* .wrap-content-fiche {
	.col-md-8 {
		width: 55%;
	}
	.col-md-4 {
		width: 40%;
	}
}*/
.sidebar-fiche .bloc-cat {
  display: block !important;
  width: 100%;
  min-height: 200px; }

.actus_date,
.outils_categorie i.icon-tag:before,
.content-RTE li::before,
.content-RTE h4,
.wrap-info-pratique .titre-info i:before {
  color: #9cc400 !important; }

.content-RTE p,
.content-RTE p strong,
.content-RTE li,
.content-RTE li strong,
.outils_categorie .txt_categorie {
  color: #929292 !important; }

.content-RTE blockquote p,
.content-RTE .quote-RTE {
  font-size: 18px !important;
  padding: 0 20pt;
  margin: 30pt 0;
  line-height: 1.5;
  border-left: 5pt solid #9cc400;
  color: #474748 !important; }

.content-RTE h3 {
  color: #8d469d !important; }

.content-RTE table.datatable td,
.content-RTE table.datatable th {
  border-bottom: 1pt solid #dddcdb !important;
  padding-top: 15pt;
  padding-bottom: 15pt; }

hr {
  border-top-color: #dddcdb !important; }

.wrap-info-pratique.infos {
  margin-top: 0; }
  .wrap-info-pratique.infos h2 {
    border: none;
    border-top: 1pt solid #dddcdb;
    border-right: 1pt solid #dddcdb;
    height: 75px;
    padding: 25px 0 0; }
  .wrap-info-pratique.infos .agenda_date {
    position: relative;
    float: left;
    left: 0;
    width: auto;
    height: auto;
    border: 1pt solid #dddcdb;
    border-bottom: none;
    padding: 4pt 15pt;
    height: 75px; }
    .wrap-info-pratique.infos .agenda_date .agenda_jour {
      font-size: 28px; }
    .wrap-info-pratique.infos .agenda_date .agenda_mois {
      font-size: 14px; }
    .wrap-info-pratique.infos .agenda_date .agenda_jour, .wrap-info-pratique.infos .agenda_date .agenda_mois {
      color: #474748 !important;
      line-height: 1.5; }
  .wrap-info-pratique.infos ul {
    clear: both;
    display: flex;
    flex-wrap: wrap;
    border-top: 1pt solid #dddcdb;
    border-left: 1pt solid #dddcdb;
    /* li:nth(1+2n) {
			clear:both
		}*/ }
    .wrap-info-pratique.infos ul li {
      width: 50%;
      border: none;
      border-right: 1pt solid #dddcdb;
      border-bottom: 1pt solid #dddcdb;
      padding: 10pt 15pt; }

.footer-print {
  width: 100%;
  display: block !important;
  /*position: fixed;
	bottom: -2mm;*/ }
  .footer-print img {
    width: 100%; }
